import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Card,
    CardHeader,
    Stack,
    Container,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    ToggleButton,
    FormControlLabel,
    Switch,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
// components
import { useGetTeamById } from '../../services/useTeamServices';
import Iconify from '../../components/iconify';

export const TeamDetails = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { teamId } = useParams();

    const { data, getData } = useGetTeamById();

    const [team, setTeam] = useState({
        name: ''
    });

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (data) {
            setTeam({ name: data.name });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, navigate]);

    useEffect(() => {
        if (teamId > 0) {
            getData(teamId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamId]);

    return (
        <>
            <Helmet>
                <title> Panel: Times | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Detalhes Times
                    </Typography>
                </Stack>
                <Grid spacing={2} container direction={isLargeScreen ? 'row' : 'column'}>
                    <Grid spacing={2} container item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card>
                                <CardHeader title='Dados sobre o Time' />
                                <Stack margin={2} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Nome do local"
                                        value={team.name}
                                    />
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}
