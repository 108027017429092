import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Card,
    CardHeader,
    Stack,
    Container,
    Typography,
    TextField,
    Grid,
    MenuItem,
    Button
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetCampaignDetailsById } from '../../services/useCampaignServices';
import { getCampaignStatus } from '../../utils/getStatus';
import { MoreMenu } from '../../components/MoreMenu';
import Iconify from '../../components/iconify/Iconify';

export const CampaignDetails = () => {
    const { data, getData } = useGetCampaignDetailsById();

    const { campaignId } = useParams();

    const [campaignData, setCampaignData] = useState({
        campaignId: 0,
        createAt: "2023-01-01",
        endDate: "2023-01-01",
        partner: { partnerId: 0, partnerName: '', },
        partnerId: 0,
        startDate: "2023-01-01",
        status: 1,
        title: ""
    })

    useEffect(() => {
        if (data) {
            setCampaignData(data);
        }
    }, [data]);

    useEffect(() => {
        getData(campaignId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title> Panel: Campanha | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Detalhes da Campanha
                    </Typography>
                </Stack>
                <Grid spacing={2} container direction={'row'}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Card>
                            <CardHeader title='Dados sobre a campanha' action={
                                <MoreMenu>
                                    <MenuItem onClick={() => console.log(true)}>
                                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                        Editar
                                    </MenuItem>
                                </MoreMenu>
                            } />
                            <Stack margin={2} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    value={campaignData.title}
                                />
                                <TextField
                                    fullWidth
                                    label="Status"
                                    value={getCampaignStatus(campaignData.status)}
                                />
                                <TextField
                                    fullWidth
                                    label="Data de ínicio"
                                    value={moment(campaignData.startDate).format('DD/MM/YYYY')}
                                />
                                <TextField
                                    fullWidth
                                    label="Data de término"
                                    value={moment(campaignData.endDate).format('DD/MM/YYYY')}
                                />
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card>
                            <CardHeader title='Dados sobre o Parceiro' />
                            <Stack margin={2} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Parceiro"
                                    value={campaignData.partner.partnerName}
                                />
                                
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
