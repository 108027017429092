import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Toolbar
} from '@mui/material';
import { debounce } from 'lodash';
import { styled, alpha } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { useListField } from '../../services/useFieldServices';
import { MoreMenu } from './components/MoreMenu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'fieldId', label: 'ID', alignRight: false },
  { id: 'fieldName', label: 'Nome', alignRight: false },
  { id: 'fieldType', label: 'Type', alignRight: false },
  { id: 'floorType', label: 'floor', alignRight: false },
  { id: 'isActive', label: 'Ativo?', alignRight: false },
  { id: 'address', label: 'Endereço', alignRight: false },
  { id: '' },
];

const FieldTypes = [{ id: 1, value: "Campo" }, { id: 2, value: "Society" }, { id: 3, value: "Futsal" }];

const FieldFloorType = [
  { id: 1, value: "Grama Natural" },
  { id: 2, value: "Grama Sintética" },
  { id: 3, value: "Concreto" },
  { id: 4, value: "Cimento Liso" },
  { id: 5, value: "Cimento Rústico" },
  { id: 6, value: "Paviflex" },
  { id: 7, value: "Borracha" },
  { id: 8, value: "Taco" },
  { id: 9, value: "Granalite" }
];

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 380,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export const FieldList = () => {
  const [fields, setFields] = useState({
    data: [],
    currentPage: 0,
    totalItems: 0,
    totalPages: 0
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [keywords, setKeywords] = useState('');

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 20) : 0;

  const { data, getData, isLoading } = useListField();


  const debouncedApiCall = useCallback(
    debounce((text) => getData({ keyword: text, page: 0, pageSize: rowsPerPage }), 1500),
    []
  );

  const onKeywordsChange = (e) => {
    setKeywords(e.target.value);
    setPage(0);
    debouncedApiCall(e.target.value);
  };

  useEffect(() => {
    if (data) {
      setFields(data)
    }
  }, [data]);

  useEffect(() => {
    getData({ keyword: keywords, page, pageSize: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> Panel: Campos & Quadras | VarzeaFut</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Campos & Quadras
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to="/app/field/add"
          >
            Novo
          </Button>
        </Stack>

        <Card style={{ marginBottom: 10 }}>
          <StyledRoot>
            <StyledSearch
              placeholder="Busca"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              value={keywords}
              onChange={onKeywordsChange}
            />
          </StyledRoot>
        </Card>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.data?.map((row) => {
                    const { fieldId, fieldName, fieldType, floorType, isActive, city, state, zipcode } = row;
                    return (
                      <TableRow hover key={fieldId} tabIndex={-1}>
                        <TableCell>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {fieldId}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{fieldName}</TableCell>
                        <TableCell align="left">{FieldTypes.filter(p => p.id === fieldType)[0]?.value}</TableCell>
                        <TableCell align="left">{FieldFloorType.filter(p => p.id === floorType)[0]?.value}</TableCell>
                        <TableCell align="left">
                          <Label color={isActive ? 'success' : 'error'}>{isActive ? 'Ativo' : 'Desativado'}</Label>
                        </TableCell>
                        <TableCell align="left">{`${city}/${state} - ${zipcode}`}</TableCell>
                        <TableCell align="right">
                          <MoreMenu fieldId={fieldId} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isLoading && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {fields.length === 0 && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Sem resultados para &nbsp;
                            <strong>&quot;{""}&quot;</strong>.
                            <br /> Tente usando outras palavras
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={fields?.totalItems ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </Card>
      </Container>
    </>
  );
}
