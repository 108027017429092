import React, { useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
    Stack,
    TextField,
    Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { VzDialog } from '../../../components/VzDialog';
import { useUpdatePush } from '../../../services/usePushServices';

export const EditPushData = ({ open, setOpen, data, setPushDetails }) => {
    const { doRequest, data: success, isLoading } = useUpdatePush();

    const formSchema = Yup.object().shape({
        title: Yup.string().required('Campo obrigatório'),
        content: Yup.string().required('Campo obrigatório'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            content: ''
        },
        validationSchema: formSchema,
        onSubmit: async (formData) => {
            const item = {
                ...formData,
                pushId: data.pushId
            }
            await doRequest(data.pushId, item);
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (data) {
            setFieldValue('title', data.title);
            setFieldValue('content', data.content);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (success && open) {
            setPushDetails(success);
            setOpen(false)
        }
    });

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Editar Dados">
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack margin={2} spacing={2}>
                        <TextField
                            fullWidth
                            label="Titúlo"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                        />
                        <TextField
                            fullWidth
                            label="Conteúdo"
                            {...getFieldProps('content')}
                            error={Boolean(touched.content && errors.content)}
                            helperText={touched.content && errors.content}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mt={5} spacing={2}>
                        <Button variant="contained" color="error" size="large" onClick={() => setOpen(false)}>Cancelar</Button>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting || isLoading}
                        >
                            Salvar
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </VzDialog>
    );
};

EditPushData.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
    setPushDetails: PropTypes.func
};
