// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Relatórios',
    path: '/app/report',
    icon: icon('ic_reports'),
  },
  {
    title: 'Push',
    path: '/app/push',
    icon: icon('ic_notification'),
  },
  // {
  //   title: 'Parceiros',
  //   path: '/app/partner',
  //   icon: icon('ic_partners'),
  // },
  // {
  //   title: 'Campanhas',
  //   path: '/app/campaign',
  //   icon: icon('ic_campaign'),
  // },
  // {
  //   title: 'Campos & Quadras',
  //   path: '/app/field',
  //   icon: icon('ic_field'),
  // },
  // {
  //   title: 'Times',
  //   path: '/app/team',
  //   icon: icon('ic_teams'),
  // },
  {
    title: 'Usuários',
    path: '/app/user',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'product',
  //   path: '/app/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/app/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
