import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import {
    Card,
    CardHeader,
    Stack,
    Button,
    Container,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
// components
import { useCreateCampaign } from '../../services/useCampaignServices';
import Iconify from '../../components/iconify';
import { SearchPartner } from './components/SearchPartner';

export const CampaignAdd = () => {

    const navigate = useNavigate();
    const { doRequest, data: result, isLoading } = useCreateCampaign();
    const [openSearch, setOpenSearch] = useState(false);
    const [partner, setPartner] = useState({ partnerId: 0, partnerName: '' });

    const formSchema = Yup.object().shape({
        title: Yup.string().required('Campo obrigatório'),
        startDate: Yup.string().required('Campo obrigatório').matches(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/, 'Data inválida (dd/mm/yyyy)'),
        endDate: Yup.string().required('Campo obrigatório').matches(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/, 'Data inválida (dd/mm/yyyy)'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            startDate: '',
            endDate: ''
        },
        validationSchema: formSchema,
        onSubmit: async (formData) => {
            await doRequest({
                title: formData.title,
                startDate: moment(formData.startDate, 'DD/MM/YYYY').toDate(),
                endDate: moment(formData.endDate, 'DD/MM/YYYY').toDate(),
                partnerId: partner.partnerId
            });
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    useEffect(() => {
        if (result && result?.campaignId !== "") {
            navigate(`/app/campaign`, { replace: true });
        }
    }, [result, navigate]);

    return (
        <>
            <Helmet>
                <title> Panel: Campanhas | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Adicionar Campanha
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid spacing={2} container direction={'row'}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card>
                                    <CardHeader title='Dados sobre o campanha' />
                                    <Stack margin={2} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Parceiro"
                                            value={partner?.partnerName}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setOpenSearch(true)} edge="end">
                                                            <Iconify icon={'eva:plus-outline'} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Titúlo"
                                            {...getFieldProps('title')}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Data de Inicío"
                                            {...getFieldProps('startDate')}
                                            error={Boolean(touched.startDate && errors.startDate)}
                                            helperText={touched.startDate && errors.startDate}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Data do Fim"
                                            {...getFieldProps('endDate')}
                                            error={Boolean(touched.endDate && errors.endDate)}
                                            helperText={touched.endDate && errors.endDate}
                                        />
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} mt={5} spacing={2}>
                                    <Button variant="contained" color="error" size="large" component={RouterLink} to="/app/campaign">Cancelar</Button>
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting || isLoading}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                    <SearchPartner open={openSearch} setOpen={setOpenSearch} setResult={(value) => setPartner(value)} />
                </FormikProvider>
            </Container >
        </>
    );
}
