import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Card,
    CardHeader,
    Stack,
    Container,
    Typography,
    TextField,
    Grid,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
// components
import { useGetReportById, useDownloadReport } from '../../services/useReportsServices';

export const ReportExecution = () => {

    const navigate = useNavigate();
    const { data, getData, isLoading } = useGetReportById();
    const { doRequest, blob, isLoading: isDownloading } = useDownloadReport();
    const { reportId } = useParams();
    const [report, setReport] = useState({ name: '', querySql: '', filters: '' });
    const [filters, setFilters] = useState({});

    const doDownload = () => {
        doRequest(reportId, filters);
    };

    useEffect(() => {
        if (data) {
            setReport({
                name: data.name,
                querySql: data.querySql,
                filters: data.filters
            });

            if (data.filters?.length > 0 && data.filters?.split(',').length > 0) {
                const items = data.filters?.split(',');
                const item = { filter01: '0' };
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < items.length; i++) {
                    item[items[i]] = '';
                }
                setFilters(item)
            }
        }
    }, [data]);

    useEffect(() => {
        if (blob) {
            const fileURL = window.URL.createObjectURL(blob.data);
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = `relatório_${Math.floor(Date.now() / 1000)}.csv`;
            a.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blob]);

    useEffect(() => {
        getData(reportId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title> Panel: Relatórios | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Executar Relatório
                    </Typography>
                </Stack>

                <Grid spacing={2} container direction={'row'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardHeader title='Dados sobre o relatório' />
                            <Stack margin={2} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    value={report.name}
                                />
                                <TextField
                                    fullWidth
                                    label="Query SQL"
                                    value={report.querySql}
                                />
                            </Stack>
                        </Card>
                    </Grid>
                    {data?.filters?.length > 0 && data?.filters?.split(',').length > 0 && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardHeader title='Campos do relatório' />
                            <Stack margin={2} spacing={2}>
                                {report.filters.split(',').map(p =>
                                (<TextField
                                    key={`filter_${p}`}
                                    fullWidth
                                    label={p}
                                    onChange={(e) => setFilters({ ...filters, [p]: e.target.value })}
                                    value={filters[p]}
                                />)
                                )}
                            </Stack>
                        </Card>
                    </Grid>)}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} mt={5} spacing={2}>
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => doDownload()}
                                loading={isDownloading}
                            >
                                Executar
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}
