import { useState } from 'react';
import { http, configs, convertToQuery } from '../utils/http-common';

export const useListTeam = () => {
    const [data, setData] = useState({ data: [] });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (filter) => {
        setData([]);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Team?${convertToQuery(filter)}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetTeamById = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (teamId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Team/${teamId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}
