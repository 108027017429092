import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import {
    Card,
    CardHeader,
    Stack,
    Button,
    MenuItem,
    Container,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    ToggleButton,
    FormControlLabel,
    Switch
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// components
import { useGetAddress } from '../../services/useViaCep';
import { useCreateField } from '../../services/useFieldServices';

const FacilitiesList = [
    { name: 'bar', text: 'Bar' },
    { name: 'bleachers', text: 'Arquibancada' },
    { name: 'covered', text: 'Quadra Coberta' },
    { name: 'eletronic-board', text: 'Plaar eletrônico' },
    { name: 'free-parking', text: 'Estacionamento Gratuito' },
    { name: 'grill', text: 'Churrasqueira' },
    { name: 'locker', text: 'Vestiário' },
    { name: 'paid-parking', text: 'Estacionamento Pago' },
    { name: 'restaurant', text: 'Lanchonete' },
    { name: 'shower', text: 'Chuveiro' },
    { name: 'wi-fi', text: 'Wi-Fi' },
];

export const FieldAdd = () => {

    const theme = useTheme();
    const navigate = useNavigate();

    const { getAddress, data: addressResult } = useGetAddress();
    const { doRequest, data: result } = useCreateField();

    const formSchema = Yup.object().shape({
        fieldName: Yup.string().required(),
        fieldType: Yup.string().required(),
        rate: Yup.string().required(),
        floorType: Yup.string().required(),
        city: Yup.string().required(),
        zipcode: Yup.string().required().matches("[0-9]{5}-[0-9]{3}"),
        street: Yup.string().required(),
        number: Yup.string().required(),
    });

    const [facilities, setFacilities] = useState([]);
    const [isActive, setIsActive] = useState(false);

    const formik = useFormik({
        initialValues: {
            fieldName: '',
            fieldType: '',
            rate: 0,
            floorType: '',
            openTime: '',
            city: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            state: '',
            region: '',
            district: ''
        },
        validationSchema: formSchema,
        onSubmit: async (formData) => {
            const item = {
                ...formData,
                facilities: facilities.length > 0 ? facilities.join(',') : '',
                isActive
            };
            doRequest(item);
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (addressResult?.logradouro) {
            setFieldValue('street', addressResult?.logradouro)
        }
        if (addressResult?.complemento) {
            setFieldValue('complement', addressResult?.complemento)
        }
        if (addressResult?.bairro) {
            setFieldValue('district', addressResult?.bairro)
        }
        if (addressResult?.localidade) {
            setFieldValue('city', addressResult?.localidade)
        }
        if (addressResult?.uf) {
            setFieldValue('state', addressResult?.uf)
        }
    }, [addressResult, setFieldValue]);

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const toogleFacilities = (facility) => {
        if (facilities.filter(p => p === facility).length > 0) {
            setFacilities([...facilities.filter(p => p !== facility)])
        } else {
            setFacilities([...facilities, facility])
        }
    }

    const toggleFacilitiesSelected = (facility) => facilities.filter(p => p === facility).length > 0;

    useEffect(() => {
        if (result && result?.fieldId > 0) {
            navigate(`/app/field/details/${result.fieldId}`, { replace: true });
        }
    }, [result, navigate]);

    return (
        <>
            <Helmet>
                <title> Panel: Campos & Quadras | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Adicionar Campos & Quadras
                    </Typography>
                </Stack>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid spacing={2} container direction={isLargeScreen ? 'row' : 'column'}>
                            <Grid spacing={2} container item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card>
                                        <CardHeader title='Dados sobre o Campo/Quadra' />
                                        <Stack margin={2} spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Nome do local"
                                                {...getFieldProps('fieldName')}
                                                error={Boolean(touched.fieldName && errors.fieldName)}
                                                helperText={touched.fieldName && errors.fieldName}
                                            />
                                            <TextField
                                                label="Tipos de Campo"
                                                select
                                                {...getFieldProps('fieldType')}
                                                error={Boolean(touched.fieldType && errors.fieldType)}
                                                helperText={touched.fieldType && errors.fieldType}
                                            >
                                                <MenuItem value="1">Campo</MenuItem>
                                                <MenuItem value="2">Society</MenuItem>
                                                <MenuItem value="3">Futsal</MenuItem>
                                            </TextField>
                                            <TextField
                                                select
                                                label="Tipo de piso"
                                                {...getFieldProps('floorType')}
                                                error={Boolean(touched.floorType && errors.floorType)}
                                                helperText={touched.floorType && errors.floorType}
                                            >
                                                <MenuItem value="1">Grama Natural</MenuItem>
                                                <MenuItem value="2">Grama Sintética</MenuItem>
                                                <MenuItem value="3">Concreto</MenuItem>
                                                <MenuItem value="4">Cimento Liso</MenuItem>
                                                <MenuItem value="5">Cimento Rústico</MenuItem>
                                                <MenuItem value="6">Paviflex</MenuItem>
                                                <MenuItem value="7">Borracha</MenuItem>
                                                <MenuItem value="8">Taco</MenuItem>
                                                <MenuItem value="9">Granalite</MenuItem>
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                label="Avaliação"
                                                {...getFieldProps('rate')}
                                                error={Boolean(touched.rate && errors.rate)}
                                                helperText={touched.rate && errors.rate}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Horários"
                                                {...getFieldProps('openTime')}
                                                error={Boolean(touched.openTime && errors.openTime)}
                                                helperText={touched.openTime && errors.openTime}
                                            />
                                            <Stack alignItems="flex-end">
                                                <FormControlLabel control={<Switch checked={isActive} onChange={event => setIsActive(event.target.checked)} />} label="Está Ativo?" />
                                            </Stack>
                                        </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card>
                                        <CardHeader title='Facilidades' />
                                        <Stack direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                            flexWrap="wrap">
                                            {FacilitiesList.map(p => (
                                                <ToggleButton
                                                    key={p.name}
                                                    value={p.name}
                                                    color="info"
                                                    style={{ marginBottom: 10 }}
                                                    selected={toggleFacilitiesSelected(p.name)}
                                                    onChange={() => toogleFacilities(p.name)}
                                                >
                                                    {p.text}
                                                </ToggleButton>
                                            ))}
                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Card>
                                    <CardHeader title="Endereço" />
                                    <Stack margin={2} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="CEP"
                                            {...getFieldProps('zipcode')}
                                            error={Boolean(touched.zipcode && errors.zipcode)}
                                            helperText={touched.zipcode && errors.zipcode}
                                            onKeyUp={(event) => {
                                                if (event.target.value.replace('-', '').length >= 8) {
                                                    getAddress(event.target.value.replace('-', ''));
                                                }
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Logradouro"
                                            {...getFieldProps('street')}
                                            error={Boolean(touched.street && errors.street)}
                                            helperText={touched.street && errors.street}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Número"
                                            {...getFieldProps('number')}
                                            error={Boolean(touched.number && errors.number)}
                                            helperText={touched.number && errors.number}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Complemento"
                                            {...getFieldProps('complement')}
                                            error={Boolean(touched.complement && errors.complement)}
                                            helperText={touched.complement && errors.complement}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Bairro"
                                            {...getFieldProps('district')}
                                            error={Boolean(touched.district && errors.district)}
                                            helperText={touched.district && errors.district}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Região"
                                            {...getFieldProps('region')}
                                            error={Boolean(touched.region && errors.region)}
                                            helperText={touched.region && errors.region}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Cidade"
                                            {...getFieldProps('city')}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                        />
                                        <TextField
                                            select
                                            label="Estado"
                                            {...getFieldProps('state')}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                        >
                                            <MenuItem value="AC">Acre</MenuItem>
                                            <MenuItem value="AL">Alagoas</MenuItem>
                                            <MenuItem value="AP">Amapá</MenuItem>
                                            <MenuItem value="AM">Amazonas</MenuItem>
                                            <MenuItem value="BA">Bahia</MenuItem>
                                            <MenuItem value="CE">Ceará</MenuItem>
                                            <MenuItem value="DF">Distrito Federal</MenuItem>
                                            <MenuItem value="ES">Espírito Santo</MenuItem>
                                            <MenuItem value="GO">Goiás</MenuItem>
                                            <MenuItem value="MA">Maranhão</MenuItem>
                                            <MenuItem value="MT">Mato Grosso</MenuItem>
                                            <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                            <MenuItem value="MG">Minas Gerais</MenuItem>
                                            <MenuItem value="PA">Pará</MenuItem>
                                            <MenuItem value="PB">Paraíba</MenuItem>
                                            <MenuItem value="PR">Paraná</MenuItem>
                                            <MenuItem value="PE">Pernambuco</MenuItem>
                                            <MenuItem value="PI">Piauí</MenuItem>
                                            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                            <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                                            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                                            <MenuItem value="RO">Rondônia</MenuItem>
                                            <MenuItem value="RR">Roraima</MenuItem>
                                            <MenuItem value="SC">Santa Catarina</MenuItem>
                                            <MenuItem value="SP">São Paulo</MenuItem>
                                            <MenuItem value="SE">Sergipe</MenuItem>
                                            <MenuItem value="TO">Tocantins</MenuItem>
                                        </TextField>
                                    </Stack>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} mt={5} spacing={2}>
                                    <Button variant="contained" color="error" size="large" component={RouterLink} to="/app/field">Cancel</Button>
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        Save
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Container >
        </>
    );
}
