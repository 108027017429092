import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Stack,
    Button,
    ToggleButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { VzDialog } from '../../../components/VzDialog';
import { useUpdateFieldFaciliites as useUpdateFieldFacilites } from '../../../services/useFieldServices';

export const EditFacilities = ({ open, setOpen, data, refreshData }) => {

    const FacilitiesList = [
        { name: 'bar', text: 'Bar' },
        { name: 'bleachers', text: 'Arquibancada' },
        { name: 'covered', text: 'Quadra Coberta' },
        { name: 'eletronic-board', text: 'Plaar eletrônico' },
        { name: 'free-parking', text: 'Estacionamento Gratuito' },
        { name: 'grill', text: 'Churrasqueira' },
        { name: 'locker', text: 'Vestiário' },
        { name: 'paid-parking', text: 'Estacionamento Pago' },
        { name: 'restaurant', text: 'Lanchonete' },
        { name: 'shower', text: 'Chuveiro' },
        { name: 'wi-fi', text: 'Wi-Fi' },
    ];

    const { doRequest, data: success } = useUpdateFieldFacilites();

    const [facilities, setFacilities] = useState([]);

    const toogleFacilities = (facility) => {
        if (facilities.filter(p => p === facility).length > 0) {
            setFacilities([...facilities.filter(p => p !== facility)])
        } else {
            setFacilities([...facilities, facility])
        }
    }

    const toggleFacilitiesSelected = (facility) => facilities.filter(p => p === facility).length > 0;

    const saveFacilities = () => {
        doRequest(data.fieldId, { fieldId: data.fieldId, facilities: facilities.join(',') });
    };

    useEffect(() => {
        if (data && data.facilities?.length > 0) {
            setFacilities(data.facilities?.split(','));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (success && open) {
            refreshData(data.fieldId);
            setOpen(false)
        }
    });

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Editar as Facilidades do local">
            <Stack direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                padding={2}
                flexWrap="wrap">
                {FacilitiesList.length > 0 && FacilitiesList.map(p => (
                    <ToggleButton
                        key={p.name}
                        value={p.name}
                        color="info"
                        style={{ marginBottom: 10 }}
                        selected={toggleFacilitiesSelected(p.name)}
                        onChange={() => toogleFacilities(p.name)}
                    >
                        {p.text}
                    </ToggleButton>
                ))}
            </Stack>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mt={5} spacing={2}>
            <Button variant="contained" color="error" size="large" onClick={() => setOpen(false)}>Cancelar</Button>
                <LoadingButton
                    size="large"
                    variant="contained"
                    onClick={saveFacilities}
                >
                    Salvar
                </LoadingButton>
            </Stack>
        </VzDialog>
    );
};

EditFacilities.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
    refreshData: PropTypes.func
};
