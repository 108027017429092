import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Button,
  Stack,
  TextField,
  InputAdornment
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { eventBus } from '../../../utils/eventBus';
import Iconify from '../../../components/iconify';
import { AuthUpdateAttributes, GetCurrentUser } from '../../../utils/userAdmin';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(3),
}));

export const ProfileForm = () => {
  const [profile, setProfile] = useState({ email: '', });

  const schema = Yup.object().shape({
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Full name required'),
    phone: Yup.number().required('Phone required'),
    country: Yup.string().required('Country is required'),
    language: Yup.string().required('Language is required')
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      phone: '',
      country: '',
      language: ''
    },
    validationSchema: schema,
    onSubmit: async (formProps) => {
      const userData = {
        fullname: formProps.fullname,
        phone: `+${formProps.phone}`,
        country: formProps.country,
        language: formProps.language
      };

      const isSaved = await AuthUpdateAttributes(userData);

      if (isSaved) {
        const message = 'Profile updated with success';
        eventBus.emit('SNACKBAR_SHOW', { severity: 'success', message });
        window.location.href = '/app'
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    GetCurrentUser().then((user) => {
      if (user !== null) {
        setProfile({
          email: user.attributes.email,
        });
      }

      setFieldValue('fullname', user.attributes.name);
      setFieldValue('phone', user.attributes.phone_number.replace('+', ''));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <RootStyle>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Full Name"
                {...getFieldProps('fullname')}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={touched.fullname && errors.fullname}
              />
              <TextField
                fullWidth
                label="Email Address (Disabled)"
                value={profile.email}
              />
              <TextField
                fullWidth
                label="Phone"
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify  icon={'eva:search-fill'} sx={{ mr: 2 }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={2}>
                <Button variant="contained" color="error" size="large" component={RouterLink} to="/app">Cancel</Button>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </RootStyle>
        </Card>
      </Form>
    </FormikProvider>
  );
};
