import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TableContainer,
    MenuItem,
    CircularProgress
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MoreMenu } from '../../components/MoreMenu';
import { useListReports, useRemoveReport } from '../../services/useReportsServices';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'reportId', label: 'ID', alignRight: false },
    { id: 'name', label: 'Nome', alignRight: false },
    { id: '' }
];

// ----------------------------------------------------------------------

export const ReportList = () => {
    const [reportsData, setReportsData] = useState([]);
    const { data, getData, isLoading } = useListReports();
    const { doRequest: doRemove, data: resultRemove, isLoading: isRemoving } = useRemoveReport();

    useEffect(() => {
        if (data) {
            setReportsData(data);
        }
    }, [data]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultRemove]);

    return (
        <>
            <Helmet>
                <title> Panel: Relatórios | VarzeaFut</title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Relatórios
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        component={RouterLink}
                        to="/app/report/add">
                        Novo Relatório
                    </Button>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                            >
                                                {headCell.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportsData?.length > 0 && reportsData.map((row) => {
                                        const { reportId, name } = row;

                                        return (
                                            <TableRow hover key={reportId} tabIndex={-1}>
                                                <TableCell>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {reportId}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{name}</TableCell>
                                                <TableCell align="right">
                                                    <MoreMenu>
                                                        <MenuItem component={RouterLink} to={`/app/report/edit/${reportId}`}>
                                                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                                            Editar
                                                        </MenuItem>
                                                        <MenuItem onClick={() => doRemove(reportId)}>
                                                            <Iconify icon={'eva:trash-fill'} sx={{ mr: 2 }} />
                                                            Remover
                                                        </MenuItem>
                                                        <MenuItem component={RouterLink} to={`/app/report/execution/${reportId}`}>
                                                            <Iconify icon={'carbon:run-mirror'} sx={{ mr: 2 }} />
                                                            Executar
                                                        </MenuItem>
                                                    </MoreMenu>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {(isLoading || isRemoving) && (
                                        <TableRow style={{ height: 53 * 6 }}>
                                            <TableCell colSpan={3} align="center">
                                                <CircularProgress size={50} />
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {reportsData?.length === 0 && !isLoading && (
                                        <TableRow>
                                            <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Não encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        Sem resultados para &nbsp;
                                                        <strong>&quot;{""}&quot;</strong>.
                                                        <br /> Tente usando outras palavras
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}

