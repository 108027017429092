import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Card,
    CardHeader,
    Stack,
    Container,
    Typography,
    TextField,
    Grid,
    MenuItem,
    Table,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Avatar,
    Button
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetPushDetails, useSendPush, useCancelPush } from '../../services/usePushServices';
import { useRemovePushReceiver } from '../../services/usePushReceiverServices';
import { getPushStatus } from '../../utils/getStatus';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MoreMenu } from '../../components/MoreMenu';
import { AddClientsForm } from './components/AddClientsForm';
import { EditPushData } from './components/EditPushData';

export const PushDetails = () => {
    const { data, getData } = useGetPushDetails();
    const { doRequest: doRemoveReceiver, data: removeResult } = useRemovePushReceiver();
    const { doRequest: doSend, data: sendPushResult } = useSendPush();
    const { doRequest: doCancel, data: cancelPushResult } = useCancelPush();

    const { pushId } = useParams();
    const [pushDetails, setPushDetails] = useState({
        content: '',
        createAt: '2023-01-01',
        pushId: 0,
        status: 0,
        title: '',
        receivers: []
    });
    const [openAddReceivers, setOpenAddReceivers] = useState(false);
    const [openEditPushData, setOpenEditPushData] = useState(false);

    useEffect(() => {
        if (data) {
            setPushDetails(data);
        }
    }, [data]);

    useEffect(() => {
        if (removeResult) {
            setPushDetails(removeResult);
        }
    }, [removeResult]);
    
    useEffect(() => {
        if (cancelPushResult) {
            setPushDetails(cancelPushResult);
        }
    }, [cancelPushResult]);

    useEffect(() => {
        if (sendPushResult) {
            setPushDetails(sendPushResult);
        }
    }, [sendPushResult]);

    useEffect(() => {
        getData(pushId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title> Panel: Push | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Detalhes Push
                    </Typography>
                </Stack>

                <Grid spacing={2} container direction={'row'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardHeader title='Dados sobre o Push' action={
                                pushDetails.status === 1 && (<MoreMenu>
                                    <MenuItem onClick={() => setOpenEditPushData(true)}>
                                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                        Editar
                                    </MenuItem>
                                    <MenuItem onClick={() => doCancel(pushId)}>
                                        <Iconify icon={'ic:baseline-cancel'} sx={{ mr: 2 }} />
                                        Cancel
                                    </MenuItem>
                                    <MenuItem onClick={() => doSend(pushId)}>
                                        <Iconify icon={'ic:round-send'} sx={{ mr: 2 }} />
                                        Enviar
                                    </MenuItem>
                                </MoreMenu>)
                            } />
                            <Stack margin={2} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    value={pushDetails.title}
                                />
                                <TextField
                                    fullWidth
                                    label="Conteúdo"
                                    value={pushDetails.content}
                                />
                                <TextField
                                    fullWidth
                                    label="Status"
                                    value={getPushStatus(pushDetails.status)}
                                />
                                <TextField
                                    fullWidth
                                    label="Criado em"
                                    value={moment(pushDetails.createAt).format('DD/MM/yyyy HH:mm')}
                                />
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                {pushDetails?.status === 1 && (<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
                    <Typography variant="h4" gutterBottom />
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={() => setOpenAddReceivers(true)}
                    >
                        Adicionar Times
                    </Button>
                </Stack>)}
                {pushDetails.receivers?.length > 0 && (<Grid spacing={2} container direction={'row'} style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <Scrollbar>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={40} />
                                            <TableCell>Time</TableCell>
                                            <TableCell>Modalidade</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pushDetails.receivers.map(p => (
                                            <TableRow key={`row_receivers_${p.pushReceiverId}`}>
                                                <TableCell>
                                                    {p.shieldPath !== null && (<Avatar alt={p.teamName} src={`https://www.varzeafut.com.br/img_site/logotipo/${p.shieldPath}`} />)}
                                                    {p.shieldPath === null && (<Avatar alt={p.teamName} src={`https://www.varzeafut.com.br/imagens/Varzea-shield.png`} />)}
                                                </TableCell>
                                                <TableCell>{p.teamName}</TableCell>
                                                <TableCell>{p.modality}</TableCell>
                                                <TableCell>{p.status}</TableCell>
                                                <TableCell>
                                                    {pushDetails.status === 1 && (<MoreMenu>
                                                        <MenuItem>
                                                            <MenuItem onClick={() => doRemoveReceiver(pushDetails.pushId, [p.pushReceiverId])}>
                                                                <Iconify icon={'eva:trash-fill'} sx={{ mr: 2 }} />
                                                                Remover
                                                            </MenuItem>
                                                        </MenuItem>
                                                    </MoreMenu>)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </Card>
                    </Grid>
                </Grid>)}
            </Container>
            <AddClientsForm open={openAddReceivers} setOpen={setOpenAddReceivers} setPushDetails={setPushDetails} />
            <EditPushData open={openEditPushData} setOpen={setOpenEditPushData} setPushDetails={setPushDetails} data={pushDetails} />
        </>
    );
}
