import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    Table,
    Stack,
    Paper,
    Button,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    Toolbar
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { debounce } from 'lodash';
import Iconify from '../../../components/iconify';
import { useGetPartnerSearch } from '../../../services/usePartnerServices';
import { VzDialog } from '../../../components/VzDialog';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    marginBottom: 10,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 380,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export const SearchPartner = ({ open, setOpen, setResult }) => {

    const { getData, data: partnerData } = useGetPartnerSearch();
    const [keyword, setKeyword] = useState('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedApiCall = useCallback(debounce((text) => getData(text), 1500), []);

    const onKeywordsChange = (e) => {
        setKeyword(e.target.value);
        debouncedApiCall(e.target.value);
    };

    const doFinish = (value) => {
        setResult(value);
        setOpen(false);
    };

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Buscar cliente">

            <StyledSearch
                placeholder="Busca"
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
                value={keyword}
                onChange={onKeywordsChange}
            />

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Parceiro</TableCell>
                            <TableCell width={50} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partnerData?.map(p => (
                            <TableRow key={`partner_line_${p.partnerId}`}>
                                <TableCell>{p.partnerId}</TableCell>
                                <TableCell>{p.partnerName}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => doFinish(p)}>
                                    <Iconify icon="tabler:arrow-right" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </VzDialog>
    );
};

SearchPartner.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    setResult: PropTypes.func
};
