import { useState } from 'react';
import { http, configs } from '../utils/http-common';

export const useAddPushReceiver = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (pushId, receivers) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/PushReceiver/Add/${pushId}`, receivers, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useRemovePushReceiver = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (pushId, receivers) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/PushReceiver/Remove/${pushId}`, receivers, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}