import { useState } from 'react';
import axios from 'axios';

export const useGetAddress = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getAddress = async (zipcode) => {
        setData(null);
        setIsLoading(true);
        axios.get(`https://viacep.com.br/ws/${zipcode}/json`)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getAddress, error };
}