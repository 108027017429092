import { useState } from 'react';
import { http, configs } from '../utils/http-common';

export const useListPartner = () => {
    const [data, setData] = useState({ data: [] });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setData([]);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Partner`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetPartnerById = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (partnerId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Partner/${partnerId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetPartnerSearch = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (keyword) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Partner/Search?keywords=${keyword}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useCreatePartner = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Partner`, item, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdatePartner = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (partnerId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Partner/${partnerId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useRemovePartner = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (partnerId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.delete(`/api/Partner/${partnerId}`, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}
