import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
    Stack,
    MenuItem,
    TextField,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { VzDialog } from '../../../components/VzDialog';
import { useUpdateFieldAddress } from '../../../services/useFieldServices';
import { useGetAddress } from '../../../services/useViaCep';

export const EditAddress = ({ open, setOpen, data, refreshData }) => {
    const formSchema = Yup.object().shape({
        city: Yup.string().required(),
        zipcode: Yup.string().required().matches("[0-9]{5}-[0-9]{3}"),
        street: Yup.string().required(),
        number: Yup.string().required(),
    });

    const { getAddress, data: addressResult } = useGetAddress();
    const { doRequest, data: success } = useUpdateFieldAddress();

    const formik = useFormik({
        initialValues: {
            city: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            state: '',
            region: '',
            district: ''
        },
        validationSchema: formSchema,
        onSubmit: async (formData) => {
            const item = {
                fieldId: data.fieldId,
                ...formData,
            };
            doRequest(data.fieldId, item);
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (data) {
            setFieldValue('city', data.city);
            setFieldValue('zipcode', data.zipcode);
            setFieldValue('street', data.street);
            setFieldValue('number', data.number);
            setFieldValue('state', data.state);
            setFieldValue('region', data.region);
            setFieldValue('district', data.district);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (addressResult?.logradouro) {
            setFieldValue('street', addressResult?.logradouro)
        }
        if (addressResult?.complemento) {
            setFieldValue('complement', addressResult?.complemento)
        }
        if (addressResult?.bairro) {
            setFieldValue('district', addressResult?.bairro)
        }
        if (addressResult?.localidade) {
            setFieldValue('city', addressResult?.localidade)
        }
        if (addressResult?.uf) {
            setFieldValue('state', addressResult?.uf)
        }
    }, [addressResult, setFieldValue]);

    useEffect(() => {
        if (success && open) {
            refreshData(data.fieldId);
            setOpen(false);
        }
    });

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Editar Dados">
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack margin={2} spacing={2}>
                        <TextField
                            fullWidth
                            label="CEP"
                            {...getFieldProps('zipcode')}
                            error={Boolean(touched.zipcode && errors.zipcode)}
                            helperText={touched.zipcode && errors.zipcode}
                            onKeyUp={(event) => {
                                if (event.target.value.replace('-', '').length >= 8) {
                                    getAddress(event.target.value.replace('-', ''));
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Logradouro"
                            {...getFieldProps('street')}
                            error={Boolean(touched.street && errors.street)}
                            helperText={touched.street && errors.street}
                        />
                        <TextField
                            fullWidth
                            label="Número"
                            {...getFieldProps('number')}
                            error={Boolean(touched.number && errors.number)}
                            helperText={touched.number && errors.number}
                        />
                        <TextField
                            fullWidth
                            label="Complemento"
                            {...getFieldProps('complement')}
                            error={Boolean(touched.complement && errors.complement)}
                            helperText={touched.complement && errors.complement}
                        />
                        <TextField
                            fullWidth
                            label="Bairro"
                            {...getFieldProps('district')}
                            error={Boolean(touched.district && errors.district)}
                            helperText={touched.district && errors.district}
                        />
                        <TextField
                            fullWidth
                            label="Região"
                            {...getFieldProps('region')}
                            error={Boolean(touched.region && errors.region)}
                            helperText={touched.region && errors.region}
                        />
                        <TextField
                            fullWidth
                            label="Cidade"
                            {...getFieldProps('city')}
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                        />
                        <TextField
                            select
                            label="Estado"
                            {...getFieldProps('state')}
                            error={Boolean(touched.state && errors.state)}
                            helperText={touched.state && errors.state}
                        >
                            <MenuItem value="AC">Acre</MenuItem>
                            <MenuItem value="AL">Alagoas</MenuItem>
                            <MenuItem value="AP">Amapá</MenuItem>
                            <MenuItem value="AM">Amazonas</MenuItem>
                            <MenuItem value="BA">Bahia</MenuItem>
                            <MenuItem value="CE">Ceará</MenuItem>
                            <MenuItem value="DF">Distrito Federal</MenuItem>
                            <MenuItem value="ES">Espírito Santo</MenuItem>
                            <MenuItem value="GO">Goiás</MenuItem>
                            <MenuItem value="MA">Maranhão</MenuItem>
                            <MenuItem value="MT">Mato Grosso</MenuItem>
                            <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                            <MenuItem value="MG">Minas Gerais</MenuItem>
                            <MenuItem value="PA">Pará</MenuItem>
                            <MenuItem value="PB">Paraíba</MenuItem>
                            <MenuItem value="PR">Paraná</MenuItem>
                            <MenuItem value="PE">Pernambuco</MenuItem>
                            <MenuItem value="PI">Piauí</MenuItem>
                            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                            <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                            <MenuItem value="RO">Rondônia</MenuItem>
                            <MenuItem value="RR">Roraima</MenuItem>
                            <MenuItem value="SC">Santa Catarina</MenuItem>
                            <MenuItem value="SP">São Paulo</MenuItem>
                            <MenuItem value="SE">Sergipe</MenuItem>
                            <MenuItem value="TO">Tocantins</MenuItem>
                        </TextField>
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mt={5} spacing={2}>
                        <Button variant="contained" color="error" size="large" onClick={() => setOpen(false)}>Cancelar</Button>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Salvar
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </VzDialog>
    );
};

EditAddress.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
    refreshData: PropTypes.func
};
