import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Card,
    CardHeader,
    Stack,
    Container,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    ToggleButton,
    FormControlLabel,
    Switch,
    MenuItem,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
// components
import { useGetFieldById } from '../../services/useFieldServices';
import Iconify from '../../components/iconify';
import { EditData } from './components/EditData';
import { EditAddress } from './components/EditAddress';
import { EditFacilities } from './components/EditFacilities';
import { EditImages } from './components/EditImages';
import { EditMenu } from '../../components/EditMenu';
import { FacilitiesList, FieldFloorType, FieldTypes, States } from './components/Data';

export const FieldDetails = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { fieldId } = useParams();

    const { data, getData } = useGetFieldById();

    const [field, setField] = useState({
        fieldId: 0,
        fieldName: '',
        fieldType: '',
        rate: 0,
        floorType: '',
        openTime: '',
        city: '',
        zipcode: '',
        street: '',
        number: '',
        complement: '',
        state: '',
        region: '',
        district: '',
        isActive: false,
        facilities: '',
        images: []
    });

    const [facilities, setFacilities] = useState([]);
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const toggleFacilitiesSelected = (facility) => facilities.filter(p => p === facility).length > 0;
    const [openEditData, setOpenEditData] = useState(null);
    const [popupEditData, setPopupOpenEditData] = useState(false);
    const [openEditFacilities, setOpenEditFacilities] = useState(null);
    const [openEditAddress, setOpenEditAddress] = useState(null);
    const [popupEditAddress, setPopupOpenEditAddress] = useState(false);
    const [popupEditFacilities, setPopupOpenEditFacilities] = useState(false);

    useEffect(() => {
        if (data) {
            setField({
                fieldId: data.fieldId,
                fieldName: data.fieldName,
                fieldType: data.fieldType,
                rate: data.rate,
                floorType: data.floorType,
                openTime: data.openTime,
                city: data.city,
                zipcode: data.zipcode,
                street: data.street,
                number: data.number,
                complement: data.complement,
                state: data.state,
                region: data.region,
                district: data.district,
                isActive: data.isActive,
                facilities: data.facilities,
                images: data.images?.length > 0 ? data.images : []
            });
            setFacilities(data.facilities.split(','));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, navigate]);

    useEffect(() => {
        if (fieldId > 0) {
            getData(fieldId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldId]);

    return (
        <>
            <Helmet>
                <title> Panel: Campos & Quadras | VarzeaFut</title>
            </Helmet>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Detalhes Campos & Quadras
                    </Typography>
                </Stack>
                <Grid spacing={2} container direction={isLargeScreen ? 'row' : 'column'}>
                    <Grid spacing={2} container item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card>
                                <CardHeader title='Dados sobre o Campo/Quadra'
                                    action={
                                        <IconButton aria-label="settings" onClick={event => setOpenEditData(event.currentTarget)}>
                                            <Iconify icon={'eva:more-vertical-fill'} />
                                        </IconButton>
                                    } />
                                <Stack margin={2} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Nome do local"
                                        value={field.fieldName}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Tipos de Campo"
                                        value={field.fieldType === '' ? '' : FieldTypes.filter(p => p.id === field.fieldType)[0]?.value}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Tipo de piso"
                                        value={field.floorType === '' ? '' : FieldFloorType.filter(p => p.id === field.floorType)[0]?.value}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Avaliação"
                                        value={field.rate}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Horários"
                                        value={field.openTime}
                                    />
                                    <Stack alignItems="flex-end">
                                        <FormControlLabel control={<Switch checked={field.isActive} />} label="Está Ativo?" />
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card>
                                <CardHeader title='Facilidades'
                                    action={
                                        <IconButton aria-label="settings" onClick={event => setOpenEditFacilities(event.currentTarget)}>
                                            <Iconify icon={'eva:more-vertical-fill'} />
                                        </IconButton>
                                    } />
                                <Stack direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}
                                    padding={2}
                                    flexWrap="wrap">
                                    {FacilitiesList.map(p => (
                                        <ToggleButton
                                            key={p.name}
                                            value={p.name}
                                            color="info"
                                            style={{ marginBottom: 10 }}
                                            selected={toggleFacilitiesSelected(p.name)}
                                        >
                                            {p.text}
                                        </ToggleButton>
                                    ))}
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card>
                            <CardHeader title="Endereço"
                                action={
                                    <IconButton aria-label="settings" onClick={event => setOpenEditAddress(event.currentTarget)}>
                                        <Iconify icon={'eva:more-vertical-fill'} />
                                    </IconButton>
                                } />
                            <Stack margin={2} spacing={2}>
                                <TextField fullWidth label="CEP" value={field.zipcode} />
                                <TextField fullWidth label="Logradouro" value={field.street} />
                                <TextField fullWidth label="Número" value={field.number} />
                                <TextField fullWidth label="Complemento" value={field.complement} />
                                <TextField fullWidth label="Bairro" value={field.district} />
                                <TextField fullWidth label="Região" value={field.region} />
                                <TextField fullWidth label="Cidade" value={field.city} />
                                <TextField fullWidth label="Estado" value={field.state === '' ? '' : States.filter(p => p.name === field.state)[0]?.text} />
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <EditImages data={field} refreshData={getData} />
                    </Grid>
                </Grid>
            </Container >
            <EditMenu
                openComponent={openEditData}
                setOpenComponent={setOpenEditData}
            >
                <MenuItem onClick={() => setPopupOpenEditData(true)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar
                </MenuItem>
            </EditMenu>

            <EditMenu
                openComponent={openEditFacilities}
                setOpenComponent={setOpenEditFacilities}
            >
                <MenuItem onClick={() => setPopupOpenEditFacilities(true)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar
                </MenuItem>
            </EditMenu>

            <EditMenu
                openComponent={openEditAddress}
                setOpenComponent={setOpenEditAddress}
            >
                <MenuItem onClick={() => setPopupOpenEditAddress(true)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar
                </MenuItem>
            </EditMenu>
            <EditData data={field} open={popupEditData} setOpen={setPopupOpenEditData} refreshData={getData} />
            <EditAddress data={field} open={popupEditAddress} setOpen={setPopupOpenEditAddress} refreshData={getData} />
            <EditFacilities data={field} open={popupEditFacilities} setOpen={setPopupOpenEditFacilities} refreshData={getData} />
        </>
    );
}
