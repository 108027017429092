import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Stack,
    FormControlLabel,
    Switch,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpdateUserStatus } from '../../../services/useUsersServices';
import { VzDialog } from '../../../components/VzDialog';

export const EditUserStatus = ({ open, setOpen, data, refreshData }) => {

    const { doRequest, data: success, isLoading } = useUpdateUserStatus();
    const [isActive, setIsActive] = useState(false);

    const onSubmit = async () => {
        await doRequest(data.userId, isActive);
    }

    useEffect(() => {
        if (data) {
            setIsActive(data.isActive);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (success && open) {
            refreshData(data.userId);
            setOpen(false)
        }
    });

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Editar Status">
            <Stack margin={2} spacing={2}>
                <Stack alignItems="flex-end">
                    <FormControlLabel control={<Switch checked={isActive} onChange={event => setIsActive(event.target.checked)} />} label="Está Ativo?" />
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mt={5} spacing={2}>
                <Button variant="contained" color="error" size="large" onClick={() => setOpen(false)}>Cancelar</Button>
                <LoadingButton
                    size="large"
                    variant="contained"
                    loading={isLoading}
                    onClick={onSubmit}
                >
                    Salvar
                </LoadingButton>
            </Stack>
        </VzDialog>
    );
};

EditUserStatus.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
    refreshData: PropTypes.func
};
