import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Card, CardContent, Grid, CardHeader, IconButton, Box, CardActions, Button, Stack, Paper } from '@mui/material';
import axios from 'axios';
import { eventBus } from '../../../utils/eventBus';
import { useGetImageUrlUpload, useAddFieldImage, useRemoveFieldImage } from '../../../services/useFieldServices';
import Iconify from '../../../components/iconify';
import { EditMenu } from '../../../components/EditMenu';

export const EditImages = ({ data, refreshData }) => {

    const { getLink } = useGetImageUrlUpload();
    const { doRequest, data: success, clear } = useAddFieldImage();
    const { doRequest: doDelete } = useRemoveFieldImage();
    const [isLoading, setIsLoading] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [enabledDelete, setEnabledDelete] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [enabledMove, setEnabledMove] = useState(false);
    const [images, setImages] = useState([]);
    const [orderImages, setOrderImages] = useState([]);

    const onChangeValue = async (event) => {

        let isAllowed = false;

        switch (event.target.files[0].type) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
                isAllowed = true;
                break;
            default:
                isAllowed = false;
        }

        if (isAllowed) {
            const result = await getLink(data.fieldId, [{ imageName: event.target.files[0].name, imageContentType: event.target.files[0].type }]);
            setIsLoading(true);
            if (result !== null) {
                const links = result.data;
                const configs = { headers: { 'Content-Type': event.target.files[0].type } };
                await axios.put(links[0].signedLink, event.target.files[0], configs);
                doRequest(data.fieldId, links.map(p => ({ filename: p.imageName, mediaUrl: p.imageUrl })))
            } else {
                eventBus.emit('SNACKBAR_SHOW', { severity: 'error', message: 'Falha ao gerar link de upload' });
            }
        }
        else {
            eventBus.emit('SNACKBAR_SHOW', { severity: 'error', message: 'Arquivo não permitido' });
        }
    }

    const removeImage = async (fieldImageId) => {
        try {
            const result = await doDelete(data.fieldId, fieldImageId);
            if (result) {
                refreshData(data.fieldId);
            }
        } catch (error) {
            eventBus.emit('SNACKBAR_SHOW', { severity: 'error', message: 'Falha ao remover a imagem' });
        }
    };

    const arrayMove = (arr, oldIndex, newIndex) => {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            // eslint-disable-next-line no-plusplus
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr; // for testing
    };

    const moveToLeft = (index) => {
        if (index > 0) {
            const imgs = arrayMove(orderImages, index, index - 1);
            setOrderImages(imgs);
            console.log(imgs);
        }
    };

    const moveToRight = (index) => {
        if (index < (orderImages.length - 1)) {
            const imgs = arrayMove(orderImages, index, index + 1);
            setOrderImages(imgs);
            console.log(imgs);
        }
    };

    useEffect(() => {
        if (success && isLoading) {
            clear();
            setIsLoading(false);
            refreshData(data.fieldId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, isLoading]);

    useEffect(() => {
        if (data?.images?.length > 0) {
            setImages(data.images);
        }
    }, [data]);

    const itemFile = React.useRef(null);

    return (
        <>
            <Card>
                <CardHeader title="Imagens"
                    action={
                        <IconButton aria-label="settings" onClick={event => setOpenMenu(event.currentTarget)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                    } />
                <CardContent>
                    {data?.images?.length < 1 &&
                        <Stack alignItems="center" justifyItems="center" width="100%" height="240">
                            <input style={{ display: 'none' }} type="file" onChange={onChangeValue} ref={itemFile} accept=".png,.jpg,.jpeg" />
                            <Button variant="contained" onClick={() => itemFile.current.click()}>
                                <Iconify icon={'eva:plus-outline'} sx={{ mr: 2 }} />
                                Adicionar Imagem
                            </Button>
                        </Stack>
                    }
                    {images.length > 0 &&
                        <Grid container direction="row" spacing={2}>
                            {(!enabledMove ? images : orderImages).map((item, index) => (
                                <Grid key={item.fieldImageId} item>
                                    <Paper elevation={5}>
                                        <Card>
                                            <Box component="img" src={item.mediaUrl} alt={item.filename} loading="lazy" sx={{ maxWidth: 200 }} />
                                            {enabledDelete &&
                                                <CardActions>
                                                    <Button color="error" onClick={() => removeImage(item.fieldImageId).then()} startIcon={<Iconify icon={'eva:trash-fill'} />}>
                                                        Remover
                                                    </Button>
                                                </CardActions>}
                                            {enabledMove &&
                                                <Stack flexDirection="row" justifyContent="space-between">
                                                    <IconButton onClick={() => moveToLeft(index)}>
                                                        <Iconify icon={'eva:arrow-back-outline'} />
                                                    </IconButton>
                                                    <IconButton onClick={() => moveToRight(index)}>
                                                        <Iconify icon={'eva:arrow-forward-outline'} />
                                                    </IconButton>
                                                </Stack>
                                            }
                                        </Card>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>}
                </CardContent>
            </Card>
            <EditMenu
                openComponent={openMenu}
                setOpenComponent={setOpenMenu}
            >
                <label htmlFor='upload-photo'>
                    <input
                        style={{ display: 'none' }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={onChangeValue}
                    />
                    <MenuItem>
                        <Iconify icon={'eva:plus-outline'} sx={{ mr: 2 }} />
                        Adicionar Imagem
                    </MenuItem>
                </label>
                {/* <MenuItem onClick={() => { setEnabledMove(!enabledMove); setOrderImages(images) }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar Ordem
                </MenuItem> */}
                <MenuItem onClick={() => setEnabledDelete(!enabledDelete)}>
                    <Iconify icon={'eva:trash-fill'} sx={{ mr: 2 }} />
                    {`${enabledDelete ? 'Desabilitar ' : ''}Remover Imagem`}
                </MenuItem>
            </EditMenu>
        </>
    );
};

EditImages.propTypes = {
    data: PropTypes.object,
    refreshData: PropTypes.func
};
