import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
    Stack,
    MenuItem,
    TextField,
    Button,
    FormControlLabel,
    Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { VzDialog } from '../../../components/VzDialog';
import { useUpdateFieldData } from '../../../services/useFieldServices';

export const EditData = ({ open, setOpen, data, refreshData }) => {
    const formSchema = Yup.object().shape({
        fieldName: Yup.string().required(),
        fieldType: Yup.string().required(),
        rate: Yup.number().required(),
        floorType: Yup.string().required(),
    });

    const { doRequest, data: success } = useUpdateFieldData();

    const [isActive, setIsActive] = useState(false);

    const formik = useFormik({
        initialValues: {
            fieldName: '',
            fieldType: '',
            rate: 0,
            floorType: '',
            openTime: '',
        },
        validationSchema: formSchema,
        onSubmit: async (formData) => {
            const item = {
                fieldId: data.fieldId,                
                ...formData,
                isActive
            };
            doRequest(data.fieldId, item);
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (data) {
            setFieldValue('fieldName', data.fieldName);
            setFieldValue('fieldType', data.fieldType);
            setFieldValue('floorType', data.floorType);
            setFieldValue('rate', data.rate);
            setFieldValue('openTime', data.openTime);
            setIsActive(data.isActive);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (success && open) {
            refreshData(data.fieldId);
            setOpen(false)
        }
    });

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Editar Dados">
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack margin={2} spacing={2}>
                        <TextField
                            fullWidth
                            label="Nome do local"
                            {...getFieldProps('fieldName')}
                            error={Boolean(touched.fieldName && errors.fieldName)}
                            helperText={touched.fieldName && errors.fieldName}
                        />
                        <TextField
                            label="Tipos de Campo"
                            select
                            {...getFieldProps('fieldType')}
                            error={Boolean(touched.fieldType && errors.fieldType)}
                            helperText={touched.fieldType && errors.fieldType}
                        >
                            <MenuItem value="1">Campo</MenuItem>
                            <MenuItem value="2">Society</MenuItem>
                            <MenuItem value="3">Futsal</MenuItem>
                        </TextField>
                        <TextField
                            select
                            label="Tipo de piso"
                            {...getFieldProps('floorType')}
                            error={Boolean(touched.floorType && errors.floorType)}
                            helperText={touched.floorType && errors.floorType}
                        >
                            <MenuItem value="1">Grama Natural</MenuItem>
                            <MenuItem value="2">Grama Sintética</MenuItem>
                            <MenuItem value="3">Concreto</MenuItem>
                            <MenuItem value="4">Cimento Liso</MenuItem>
                            <MenuItem value="5">Cimento Rústico</MenuItem>
                            <MenuItem value="6">Paviflex</MenuItem>
                            <MenuItem value="7">Borracha</MenuItem>
                            <MenuItem value="8">Taco</MenuItem>
                            <MenuItem value="9">Granalite</MenuItem>
                        </TextField>
                        <TextField
                            fullWidth
                            label="Avaliação"
                            {...getFieldProps('rate')}
                            error={Boolean(touched.rate && errors.rate)}
                            helperText={touched.rate && errors.rate}
                        />
                        <TextField
                            fullWidth
                            label="Horários"
                            {...getFieldProps('openTime')}
                            error={Boolean(touched.openTime && errors.openTime)}
                            helperText={touched.openTime && errors.openTime}
                        />
                        <Stack alignItems="flex-end">
                            <FormControlLabel control={<Switch checked={isActive} onChange={event => setIsActive(event.target.checked)} />} label="Está Ativo?" />
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mt={5} spacing={2}>
                    <Button variant="contained" color="error" size="large" onClick={() => setOpen(false)}>Cancelar</Button>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Salvar
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </VzDialog>
    );
};

EditData.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
    refreshData: PropTypes.func
};
