import { useState } from 'react';
import { http, configs, convertToQuery } from '../utils/http-common';

export const useListReports = () => {
    const [data, setData] = useState({ data: [] });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setData([]);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Reports`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetReportById = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (reportId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Reports/${reportId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useCreateReport = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Reports`, item, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdateReport = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (reportId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Reports/${reportId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useRemoveReport = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (reportId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.delete(`/api/Reports/${reportId}`, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useDownloadReport = () => {
    const [blob, setBlob] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (reportId, filters) => {
        setBlob(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Reports/Export/${reportId}?${convertToQuery(filters)}`, { ...cons, responseType: 'blob', })
            .then(async (response) => {
                setBlob(response);
            })
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { blob, isLoading, doRequest, error };
}
