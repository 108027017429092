import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MoreMenu } from '../../components/MoreMenu';
import { useListPushes } from '../../services/usePushServices';
import { getPushStatus } from '../../utils/getStatus';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'pushId', label: 'ID', alignRight: false },
  { id: 'title', label: 'Titúlo', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'createAt', label: 'Criado', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export const PushList = () => {
  const [pushesData, setPushesData] = useState([]);
  const { data, getData, isLoading } = useListPushes();

  useEffect(() => {
    if (data) {
      setPushesData(data)
    }
  }, [data]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Panel: Push | VarzeaFut</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Push
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to="/app/push/add">
            Novo Push
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pushesData?.length > 0 && pushesData?.map((row) => {
                    const { pushId, title, status, createAt } = row;

                    return (
                      <TableRow hover key={pushId} tabIndex={-1}>
                        <TableCell>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {pushId}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{getPushStatus(status)}</TableCell>
                        <TableCell align="left">{moment(createAt).format('DD/MM/yyyy HH:mm')}</TableCell>
                        <TableCell align="right">
                          <MoreMenu>
                            <MenuItem component={RouterLink} to={`/app/push/details/${pushId}`}>
                              <Iconify icon={'eva:search-fill'} sx={{ mr: 2 }} />
                              Detalhes
                            </MenuItem>
                          </MoreMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {(isLoading) && (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={5} align="center">
                        <CircularProgress size={50} />
                      </TableCell>
                    </TableRow>
                  )}

                  {pushesData?.length === 0 && !isLoading && (
                    <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Sem resultados para &nbsp;
                            <strong>&quot;{""}&quot;</strong>.
                            <br /> Tente usando outras palavras
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}

