import { useState } from 'react';
import { http, configs, convertToQuery } from '../utils/http-common';

export const useListPushes = () => {
    const [data, setData] = useState({ data: [] });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setData([]);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Push`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetPushById = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (pushId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Push/${pushId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetPushDetails = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (pushId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Push/Details/${pushId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useCreatePush = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Push`, item, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useSendPush = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (pushId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Push/Send/${pushId}`, {}, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useCancelPush = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (pushId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Push/Cancel/${pushId}`, {}, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdatePush = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (pushId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Push/${pushId}`, item, cons)
            .then((p) => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useRemovePush = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (pushId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.delete(`/api/Push/${pushId}`, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}
