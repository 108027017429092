import { useState } from 'react';
import { http, configs } from '../utils/http-common';

export const useListUsers = () => {
    const [data, setData] = useState({ data: [] });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setData([]);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/User`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useCreateUser = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/User`, item, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useGetUserById = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (userId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/User/${userId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}


export const useUpdateUser = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (userId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/User/${userId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdateUserStatus = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (userId, isActive) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/User/${isActive ? 'Enable' : 'Disable'}/${userId}`, {}, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}
