import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/label/Label';
import { getCampaignStatus } from '../../../utils/getStatus';

const getColor = (status) => {
    switch (status) {
        case 9:
            return 'error';
        case 4:
            return 'secondary';
        case 3:
            return 'success';
        case 2:
            return 'primary';
        case 1:
        default:
            return 'default';
    }
}

export const CampaignStatus = ({ status }) => {
    const text = getCampaignStatus(status);

    return (<Label color={getColor(status)}>{text}</Label>);
}


CampaignStatus.propTypes = {
    status: PropTypes.number
};
