import { useState } from 'react';
import { http, configs, convertToQuery } from '../utils/http-common';

export const useListField = () => {
    const [data, setData] = useState({ data: [] });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (filter) => {
        setData([]);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Field?${convertToQuery(filter)}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useGetFieldById = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async (fieldId) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.get(`/api/Field/${fieldId}`, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, getData, error };
}

export const useCreateField = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Field`, item, cons)
            .then(p => setData(p.data))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdateField = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (fieldId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Field/${fieldId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}


export const useUpdateFieldData = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (fieldId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Field/Data/${fieldId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdateFieldAddress = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (fieldId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Field/Address/${fieldId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useUpdateFieldFaciliites = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (fieldId, item) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.put(`/api/Field/Facilities/${fieldId}`, item, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    return { data, isLoading, doRequest, error };
}

export const useGetImageUrlUpload = () => {
    const getLink = async (fieldId, data) => {
        const cons = await configs();
        try {
            const result = await http.post(`/api/Field/CreateUploadLinks/${fieldId}`, data, cons);
            return result;
        } catch (error) {
            return null;
        }
    };

    return { getLink };
}

export const useAddFieldImage = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const doRequest = async (fieldId, data) => {
        setData(null);
        setIsLoading(true);
        const cons = await configs();
        http.post(`/api/Field/Images/${fieldId}`, data, cons)
            .then(() => setData(true))
            .catch(p => setError(p))
            .finally(() => setIsLoading(false));
    };

    const clear = () => {
        setData(null);
        setError(null);
    }

    return { data, isLoading, doRequest, error, clear };
}

export const useRemoveFieldImage = () => {
    const doRequest = async (fieldId, fieldImageId) => {
        const cons = await configs();
        try {
            const result = await http.delete(`/api/Field/Images/${fieldId}/${fieldImageId}`, cons);
            return result;
        } catch (error) {
            return null;
        }
    };

    return { doRequest };
}
