import axios from 'axios';
import { apiServer } from './servers';
import { GetIdToken } from './userAdmin';

export const configs = async () => ({
        headers: {
            'Access-Control-Allow-Origin': 'http://localhost:3200',
            'Access-Control-Allow-Methods': 'GET, OPTIONS, PUT, POST, DELETE',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${await GetIdToken()}`,
        }
    });

export const http = axios.create({
    baseURL: apiServer,
});


export const convertToQuery = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(
        params[key] ?? ''
      )}`)
    .join('&');