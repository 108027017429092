export const FacilitiesList = [
    { name: 'bar', text: 'Bar' },
    { name: 'bleachers', text: 'Arquibancada' },
    { name: 'covered', text: 'Quadra Coberta' },
    { name: 'eletronic-board', text: 'Plaar eletrônico' },
    { name: 'free-parking', text: 'Estacionamento Gratuito' },
    { name: 'grill', text: 'Churrasqueira' },
    { name: 'locker', text: 'Vestiário' },
    { name: 'paid-parking', text: 'Estacionamento Pago' },
    { name: 'restaurant', text: 'Lanchonete' },
    { name: 'shower', text: 'Chuveiro' },
    { name: 'wi-fi', text: 'Wi-Fi' },
];

export const FieldTypes = [{ id: 1, value: "Campo" }, { id: 2, value: "Society" }, { id: 3, value: "Futsal" }];

export const FieldFloorType = [
    { id: 1, value: "Grama Natural" },
    { id: 2, value: "Grama Sintética" },
    { id: 3, value: "Concreto" },
    { id: 4, value: "Cimento Liso" },
    { id: 5, value: "Cimento Rústico" },
    { id: 6, value: "Paviflex" },
    { id: 7, value: "Borracha" },
    { id: 8, value: "Taco" },
    { id: 9, value: "Granalite" }
];

export const States = [
    { name: "AC", text: "Acre" },
    { name: "AL", text: "Alagoas" },
    { name: "AP", text: "Amapá" },
    { name: "AM", text: "Amazonas" },
    { name: "BA", text: "Bahia" },
    { name: "CE", text: "Ceará" },
    { name: "DF", text: "Distrito Federal" },
    { name: "ES", text: "Espírito Santo" },
    { name: "GO", text: "Goiás" },
    { name: "MA", text: "Maranhão" },
    { name: "MT", text: "Mato Grosso" },
    { name: "MS", text: "Mato Grosso do Sul" },
    { name: "MG", text: "Minas Gerais" },
    { name: "PA", text: "Pará" },
    { name: "PB", text: "Paraíba" },
    { name: "PR", text: "Paraná" },
    { name: "PE", text: "Pernambuco" },
    { name: "PI", text: "Piauí" },
    { name: "RJ", text: "Rio de Janeiro" },
    { name: "RN", text: "Rio Grande do Norte" },
    { name: "RS", text: "Rio Grande do Sul" },
    { name: "RO", text: "Rondônia" },
    { name: "RR", text: "Roraima" },
    { name: "SC", text: "Santa Catarina" },
    { name: "SP", text: "São Paulo" },
    { name: "SE", text: "Sergipe" },
    { name: "TO", text: "Tocantins" }
];
