import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Toolbar
} from '@mui/material';
import { debounce } from 'lodash';
import { styled, alpha } from '@mui/material/styles';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { useListTeam } from '../../services/useTeamServices';
import { MoreMenu } from './components/MoreMenu';
import { fDate } from '../../utils/formatTime';

const TABLE_HEAD = [
  { id: 'teamId', label: 'ID', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'foundationDate', label: 'Fundado em', alignRight: false },
  { id: 'sportId', label: 'Modalidade', alignRight: false },
  { id: 'categoryId', label: 'Categoria', alignRight: false },
  { id: 'subCategoryId', label: 'Sub-Categoria', alignRight: false },
  { id: 'address', label: 'Endereco', alignRight: false },
  { id: '' },
];

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 380,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export const TeamList = () => {
  const [teams, setTeams] = useState({
    data: [],
    currentPage: 0,
    totalItems: 0,
    totalPages: 0
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [keywords, setKeywords] = useState('');

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 20) : 0;

  const { data, getData, isLoading } = useListTeam();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedApiCall = useCallback(
    debounce((text) => getData({ keyword: text, page: 0, pageSize: rowsPerPage }), 1500), []);

  const onKeywordsChange = (e) => {
    setKeywords(e.target.value);
    setPage(0);
    debouncedApiCall(e.target.value);
  };

  useEffect(() => {
    if (data) {
      setTeams(data)
    }
  }, [data]);

  useEffect(() => {
    getData({ keyword: keywords, page, pageSize: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> Panel: Times | VarzeaFut</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Times
          </Typography>
        </Stack>

        <Card style={{ marginBottom: 10 }}>
          <StyledRoot>
            <StyledSearch
              placeholder="Busca"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              value={keywords}
              onChange={onKeywordsChange}
            />
          </StyledRoot>
        </Card>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams.data?.map((row) => {
                    const { teamId, name, foundationDate, sportId, categoryId, subCategoryId, city, state } = row;
                    return (
                      <TableRow hover key={teamId} tabIndex={-1}>
                        <TableCell>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {teamId}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{fDate(foundationDate, 'dd/MM/yyyy')}</TableCell>
                        <TableCell align="left">{sportId}</TableCell>
                        <TableCell align="left">{categoryId}</TableCell>
                        <TableCell align="left">{subCategoryId}</TableCell>
                        <TableCell align="left">{`${city}/${state}`}</TableCell>
                        <TableCell align="right">
                          <MoreMenu teamId={teamId} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isLoading && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {teams.length === 0 && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Sem resultados para &nbsp;
                            <strong>&quot;{""}&quot;</strong>.
                            <br /> Tente usando outras palavras
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={teams?.totalItems ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </Card>
      </Container>
    </>
  );
}
