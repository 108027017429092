import React, { useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
    Stack,
    TextField,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useParams } from 'react-router-dom';
import { VzDialog } from '../../../components/VzDialog';
import { useAddPushReceiver } from '../../../services/usePushReceiverServices';

export const AddClientsForm = ({ open, setOpen, setPushDetails }) => {
    const formSchema = Yup.object().shape({
        ids: Yup.string().required(),        
    });
    const { pushId } = useParams();

    const { doRequest, data: success, isLoading } = useAddPushReceiver();

    const formik = useFormik({
        initialValues: {
            ids: '',            
        },
        validationSchema: formSchema,
        onSubmit: async (formData) => {
            const ids = formData.ids.split(',').map(p => parseInt(p, 10));
            doRequest(pushId, ids);
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


    useEffect(() => {
        if (success && open) {
            setPushDetails(success);
            setOpen(false)
        }
    });

    return (
        <VzDialog open={open} handleClose={() => setOpen(false)} title="Adicionar os clientes">
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack margin={2} spacing={2}>
                        <TextField
                            fullWidth
                            label="Clientes IDs"
                            placeholder="Coloque os id separados por ,   EX: 1,2,3,4"
                            {...getFieldProps('ids')}
                            error={Boolean(touched.ids && errors.ids)}
                            helperText={touched.ids && errors.ids}
                        />                        
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mt={5} spacing={2}>
                    <Button variant="contained" color="error" size="large" onClick={() => setOpen(false)}>Cancelar</Button>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting || isLoading}
                        >
                            Salvar
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </VzDialog>
    );
};

AddClientsForm.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    setPushDetails: PropTypes.func
};
