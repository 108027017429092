import { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/Auth/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { Signup } from './pages/Auth/Signup';
import { GetCurrentUser } from './utils/userAdmin';
import { ForgotPassword } from './pages/Auth/ForgotPassword';
import { ConfirmationEmail } from './pages/Auth/ConfirmationEmail';
import { ConfirmationForgotPassword } from './pages/Auth/ConfirmationForgotPassword';
import { ChallengeNewPassword } from './pages/Auth/ChallengeNewPassword';
import { FieldList, FieldDetails, FieldAdd } from './pages/Field';
import { TeamList, TeamDetails } from './pages/Team';
import { UserAdd, UserDetails, UserList, ManagerProfileScreen as ManagerProfile } from './pages/User';
import { ReportList, ReportAdd, ReportEdit, ReportExecution } from './pages/Reports';
import { PushList, PushAdd, PushDetails } from './pages/Push';
import { PartnerList, PartnerAdd, PartnerEdit } from './pages/Partner';
import { CampaignList, CampaignAdd, CampaignDetails } from './pages/Campaign';
// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component }) => {
  const [isAutheticated, setIsAutheticated] = useState(true);

  useEffect(() => {
    GetCurrentUser()
      .then((p) => {
        if (p !== null) {
          setIsAutheticated(true);
        } else {
          setIsAutheticated(false);
        }
      })
      .catch(() => setIsAutheticated(false));
  }, []);

  return isAutheticated ? <Component /> : <Navigate to="/login" />;
};


export default function Router() {
  const routes = useRoutes([
    {
      path: '/app',
      element: <PrivateRoute component={DashboardLayout} />,
      children: [
        { path: 'dashboard', element: <PrivateRoute component={DashboardAppPage} /> },
        { path: 'user', element: <PrivateRoute component={UserList} /> },
        { path: 'user/add', element: <PrivateRoute component={UserAdd} /> },
        { path: 'user/details/:userId', element: <PrivateRoute component={UserDetails} /> },
        { path: 'products', element: <PrivateRoute component={ProductsPage} /> },
        { path: 'team', element: <PrivateRoute component={TeamList} /> },
        { path: 'team/details/:teamId', element: <PrivateRoute component={TeamDetails} /> },
        { path: 'field', element: <PrivateRoute component={FieldList} /> },
        { path: 'field/add', element: <PrivateRoute component={FieldAdd} /> },
        { path: 'field/details/:fieldId', element: <PrivateRoute component={FieldDetails} /> },
        { path: 'report', element: <PrivateRoute component={ReportList} /> },
        { path: 'report/add', element: <PrivateRoute component={ReportAdd} /> },
        { path: 'report/edit/:reportId', element: <PrivateRoute component={ReportEdit} /> },
        { path: 'report/execution/:reportId', element: <PrivateRoute component={ReportExecution} /> },
        { path: 'push', element: <PrivateRoute component={PushList} /> },
        { path: 'push/add', element: <PrivateRoute component={PushAdd} /> },
        { path: 'push/details/:pushId', element: <PrivateRoute component={PushDetails} /> },
        { path: 'partner', element: <PrivateRoute component={PartnerList} /> },
        { path: 'partner/add', element: <PrivateRoute component={PartnerAdd} /> },
        { path: 'partner/edit/:partnerId', element: <PrivateRoute component={PartnerEdit} /> },
        { path: 'campaign', element: <PrivateRoute component={CampaignList} /> },
        { path: 'campaign/add', element: <PrivateRoute component={CampaignAdd} /> },
        { path: 'campaign/details/:campaignId', element: <PrivateRoute component={CampaignDetails} /> },
        { path: 'profile', element: <PrivateRoute component={ManagerProfile} /> },
        { path: '/app', element: <Navigate to="/app/dashboard" /> },
      ],
    },
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: 'login', element: <LoginPage />, },
        { path: 'register', element: <Signup />, },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'confirm-email/:email', element: <ConfirmationEmail /> },
        { path: 'confirmation-forgot-password/:email', element: <ConfirmationForgotPassword /> },
        { path: 'challenge-new-password/:email', element: <ChallengeNewPassword /> },
        { path: '404', element: <Page404 /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace />, },
  ]);

  return routes;
}
